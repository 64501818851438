import * as React from 'react';
import { graphql } from 'gatsby';
import Blog, {
  BlogSubMiniHeader,
  BlogList,
  BlogListItem,
} from '../../components/Blog';
import { Paragraph } from '../../components/Common';
import { HE } from 'src/blogs/winning-sites-built-using-react';
import {
  WinningSitesBuiltUsingReact,
  ReactVsAngular,
  LeadingTrendsInWebApplicationDevelopment,
  FrequentlyAskedQuestionsAndExamplesOfSaas,
} from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const title = "Winning Sites Built Using React";

const articleSchemaData = {
  headline: title,
  description: 'SEO description Winning Sites Built Using React',
  image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fwinning-sites-built-using-react.jpg?alt=media&token=ff2aa528-95c2-46b9-a85b-8be037738d14',
  datePublished: '2022-03-05'
};

const WinningSitesBuiltUsingReactBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={WinningSitesBuiltUsingReact.url}
      banner="/winning-sites-built-using-react/banner.jpg"
      relatedBlogs={[
        ReactVsAngular,
        LeadingTrendsInWebApplicationDevelopment,
        FrequentlyAskedQuestionsAndExamplesOfSaas,
      ]}
      title={title}
      seo={{
        title: 'SEO title Winning Sites Built Using React',
        description: 'SEO description Winning Sites Built Using React',
        image:
          'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fwinning-sites-built-using-react.jpg?alt=media&token=ff2aa528-95c2-46b9-a85b-8be037738d14',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}

      <BlogList>
        {HE.section2.ol.map((item) => (
          <BlogListItem>
            <BlogSubMiniHeader>{item.h3}</BlogSubMiniHeader>
            {item.p.map((p) => (
              <Paragraph>{p}</Paragraph>
            ))}
          </BlogListItem>
        ))}
      </BlogList>

      {HE.section3.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}
    </Blog>
  );
}

export default WinningSitesBuiltUsingReactBlog;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
