import React from 'react';
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      'בעולם הפיתוח של מערכות WEB יש מלחמת עולם בין אנגולר (Angular) לבין ריאקט (React). למרות שקשה להשוות את ספריית ריאקט לפריימוורק השלם של אנגולר, רבים ממתכנתי ה- WEB בוחרים לפתח אתרים לאינטרנט באמצעות React.',
      "פיתוח אתרים באמצעות ריאקט, מבית היוצר של ענקית הטכנולוגיה פייסבוק, זוכה להצלחה רבה בגלל יתרונות משמעותיים כמו ה- DOM הווירטואלי, הלמידה המהירה של המערכת, התחביר הייחודי מסוג JSX הדומה מאוד לשפת ה- HTML שמתקמפל בקלות לג'אווה סקריפט, הקומפיילר המצוין, הרינדור הקל בצד השרת ועוד.",
      'לכן, אין זה פלא כי אתרים מנצחים רבים הוקמו באמצעות React. כדי להמחיש ולהבין מהם היתרונות של אתרים שפותחו בריאקט, פנינו למומחים באפספורס להציג בפנינו 10 אתרים מנצחים שנבנו באמצעות React:',
    ],
  },
  section2: {
    ol: [
      {
        h3: '1. נטפליקס',
        p: [
          'אחת הדוגמאות לאתר מנצח שהוקם באמצעות React JS הוא ללא ספק האתר המוביל של נטפליקס. ראוי לציין כי כל דפי האינטרנט של נטפליקס העולמית מוגשים באמצעות ריאקט המעובד בצד השרת, הם משרתים את ה- HTML שנוצר ואז הם משרתים את האפליקציה בצד הלקוח, וכך מבנה דף הבית נשמר לאחר שהוא עבר אופטימיזציה המבטיחה חוויה עקבית.',
          'בשנת 2017 החליטה נטפליקס לחדש ולשדרג את ממשק המשתמש שלה, וכדי לייעל את הביצועים ולספק חוויית משתמש ייחודית ומלהיבה מקבלי ההחלטות בחרו בריאקט, וכיום נטפליקס נחשבת לאחר מהאתרים המנצחים והמובילים ביותר שנבנו ב- React.',
        ],
      },
      {
        h3: '2. פייסבוק',
        p: [
          'ספריית הקוד הפתוח של ריאקט פותחה במקור על ידי מפתח תוכנה בחברת פייסבוק העולמית. הרשת החברתית המובילה משתמשת באלמנטים רבים של ריאקט כמו בכפתור הלייק המפורסם וכפתור הלוג-אין. כאשר משתמש בפייסבוק לוחץ על סמל הלייק הסמל משנה את צבעו ללא צורך בטעינת הדף מחדש.',
        ],
      },
      {
        h3: '3. אינסטגרם',
        p: [
          'ב- 2012 רכשה פייסבוק העולמית את היישום אינסטגרם, ובתוך זמן קצר צוות הפיתוח של פייסבוק בנה אתר מתקדם טכנולוגית לרשת החברתית שנרכשה על ידם, לאחר שדרוג המערכת והשלמת בניית האפליקציה החדשה שהושקה במקביל בגוגל פליי ובחנות של אפל.',
        ],
      },
      {
        h3: '4. דרופבוקס (Dropbox)',
        p: [
          'האתר של דרופבוקס הוא דוגמה לאתר מנצח ומתקדם שנבנה כולו בריאקט, ורק לאחרונה יצא תיעוד מרתק ומעניין מאוד להעברת עורך ה- Hello Sign מ- jQuery לריאקט. צוות הפיתוח של דרופבוקס ציין כי ההחלטה לעבור לריאקט נבעה בין היתר מהצורך לשפר את הביצועים והשימושיות של העורך, ובשינוי שנעשה - העורך חולק ל- 4 חלקים נפרדים והשכתוב נעשה בשכבות.',
        ],
      },
      {
        h3: '5. אסנה (Asana)',
        p: [
          'אסנה, למי שעדיין לא מכיר, היא מערכת WEB מתקדמת וחדשנית לניהול פרויקטים, משימות ועבודת צוות, המערכת נמצאת בשימוש רב בחברות הייטק ובארגונים רבים בענפים אחרים. צוות הפיתוח של אסנה כותב את רוב ה- Front End של אפליקציית הווב של החברה ב- React בגלל הביצועים המעולים והיציבות המצוינת. כך החברה השיגה מטרה נוספת והיא שיפור משמעותי של חוויית המשתמש.',
        ],
      },
      {
        h3: '6. מכלול – המרכז ללימודים טכניים',
        p: [
          <>
            {
              'אפסופרס היא חברת פיתוח תוכנה מובילה בישראל, בין היתר צוות הפיתוח של החברה סייע לנציגי '
            }
            <Link to="https://michlol-tsc.co.il" external active>
              'מכלול – המרכז ללימודים טכניים'
            </Link>
            {
              ", לשפר ולשדרג את הממשקים בפלטפורמה החדשה ללימודים טכניים אונליין. המומחים של אפספורס ליוו ופיתחו את הפרויקט מא' עד ת' באופן מושלם ותוך שימוש בטכנולוגיות המתקדמות ביותר, כולל פיתוח האתר החדש ועיצוב ממשקים ידידותיים למשתמשים."
            }
          </>,
        ],
      },
      {
        h3: '7. BBC',
        p: [
          'BBC הוא תאגיד השידור הציבורי בבריטניה, התאגיד מפיק תוכניות, סדרות וסרטים בשפה האנגלית ומשדר ברוב מדינות העולם. האתר של תאגיד השידור הבריטי נבנה בריאקט ומציע חדשות מהממלכה הבריטית ומרחבי העולם, שידורים חיים, מוזיקה, תוכן חינוכי, בידור ועוד. ריאקט מסייעת למנהלי האתר לעדכן ולרענן את התכנים המוצגים באתר בקלות ובמהירות, ולהבטיח חוויית משתמש רציפה ואיכותית.',
        ],
      },
      {
        h3: '8. OKCupid',
        p: [
          'בין אם אתם מחפשים אהבה וזוגיות ובין אם אתם נמצאים בקשר זוגי – לבטח אתם מכירים את אתר ההיכרויות OKCupid. האתר המנצח נבנה בריאקט ומציע חוויית משתמש ייחודית התורמת להצלחתו.',
        ],
      },
      {
        h3: '9. Airbnb',
        p: [
          'את אתר Airbnb אין צורך להציג בפניכם, למרות שככל הנראה שלא השתמשתם בו יותר מדי בתקופת הקורונה. האתר המנצח מאפשר לנו למצוא דירות אירוח נוחות ומאובזרות במרכזי הערים ובאתרים תיירותיים רבים בכל העולם, זהו פתרון זמין ויעיל המהווה חלופה מצוינת ללינה בבתי המלון היקרים בזמן החופשות בחו"ל.',
          'ממשק המשתמש של אתר Airbnb נבנה כולו באמצעות ריאקט, ובנוסף כדאי לדעת כי בשנת 2016 צוות הפיתוח של החברה השיק ספריית בדיקות הכוללת מגוון רכיבים בקוד פתוח, כאשר הספרייה מבוססת על ריאקט ונקראת Enzyme. ה- Enzyme יצר רעש גדול בקרב קהילת המפתחים ורבים החלו להשתמש בכלי היעיל והמתקדם ורבים תרמו לפיתוחו.',
        ],
      },
      {
        h3: '10. Reddit',
        p: [
          'אתר רדיט (Reddit) המכונה בפי רבים העמוד הראשון של האינטרנט הוא למעשה אתר מסוג מדיה חברתית המזכיר במהותו לוח מודעות מקוון. האתר מאפשר למשתמשים הרשומים בו לשתף תכנים בכל נושא אפשרי וללא הגבלה, כאשר הפרסומים שזוכים לדירוג גבוה מופיעים בראש האתר.',
          'האתר של Reddit זוכה להצלחה כבירה ומהווה מקום מפגש של קהילות שונות, ניתן לדון במגוון נושאים, ללמוד, להתווכח, למצוא אנשים עם תחומי עניין ותחביבים דומים ועוד. כדאי לדעת כי צוות הפיתוח של האתר בחר ב- React בגלל הנוחות והמהירות שלו, אך עם הזמן הקוד הבסיסי של האתר השתנה ונוספו אליו אלמנטים שנכתבו בשפות תכנות כמו Python ועוד, אך עדיין יש בו אלמנטים רבים של ריאקט שהחליפו רכיבים של Front End, בעיקר באתר המיועד למובייל.',
        ],
      },
    ],
  },
  section3: {
    p: [
      <>
        {
          'לסיכום, אם אתם מעוניינים להקים אתר מנצח ומתקדם טכנולוגית - עליכם לבחור נכון חברת פיתוח תוכנה. כדי להקים אתר מנצח כדאי גם לכם לפנות לחברת Appsforce המתמחה '
        }
        <Link to={URL.FRONT_END_SERVICE} active>
          בפיתוח Front End
        </Link>
        {', '}
        <Link to={URL.FULL_STACK_SERVICE} active>
          פיתוח Full Stack
        </Link>
        {', '}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>
          אפיון ועיצוב UI/UX
        </Link>
        {' '}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>
          ואפליקציות SaaS.
        </Link>
      </>,
    ],
  },
};
